<template>
  <!-- 用户隐私协议 -->
  <div class="user-protection">
    <OldHeader
      class="head-wrap"
      scene="userHome"
      :isWhiteBg="true"
      :isShowLogin="false"
    ></OldHeader>
    <h1 class="p-title">微吼用户服务协议</h1>
    <p class="p-timer">更新日期：【2025】年【03】月【10】日</p>
    <p class="p-timer margin-24">生效日期：【2025】年【09】月【10】日</p>
    <div class="one">
      <p class="level-1 font-bold">一、总则</p>
      <section>
        <p class="level-2">欢迎您使用微吼的产品和服务。</p>
        <p class="level-2">
          北京微吼时代科技有限公司及其关联方（以下简称“微吼”）是企业级互动视频直播云平台和一站式互动视频解决方案服务商，致力于通过视频互动技术，为客户提供数字学习技术和数字营销云解决方案，助力企业实现数字化转型。微吼合法拥有并运营的微吼官方网站、APP、客户端应用程序等微吼平台，并依据《微吼用户服务协议》（以下简称“本协议”）向您提供的产品和服务等。本协议是您与微吼建立的，就您下载、安装、试用、注册、登录微吼官方网站，和/或者APP，和/或者客户应用程序等并使用微吼产品和服务所订立的协议。本规则适用于利用微吼产品和服务开展直播活动的单位或者个人（以下简称微吼“客户”）、进入直播间观看客户开展直播活动的个人（以下简称“用户”）、客户引入并在直播间提供商品或者服务的单位或者个人（以下简称“商户”。客户本身也可能是商户）。前述人员可以统称为“您”或者“用户”。
        </p>
        <p class="level-2 font-bold font-italic">
          为了更好的维护您的权益和更好的为您提供服务，请您在使用微吼产品和服务之前务必认真阅读本协议，特别是免除或者减轻微吼责任、排除或者限制您的权利、法律适用和争议解决等条款，并审慎判断风险，自主决定是否接受本协议。如果您不同意本协议，请您不要下载、安装、试用、注册、登录、使用微吼，如您进行前述操作视为您已经阅读并同意本协议，并受本协议的约束。
        </p>
        <p class="level-2 font-bold font-italic">
          根据有关法律法规的最新规定和监管要求，微吼有权对本协议内容进行修改并予以更新。若您在本协议修改后继续使用微吼服务的，表示您已充分阅读、理解并接受修改后的协议内容，也将遵循修改后的协议内容使用服务；若您不同意修改后的协议内容，您应停止使用微吼的产品和服务。
        </p>
      </section>
    </div>
    <div>
      <p class="level-1 font-bold">二、微吼服务说明</p>
      <section>
        <p class="level-2">
          您知晓并同意，微吼是一个向包括您在内的用户提供网络直播的技术服务平台，微吼本身不直接生产直播内容。您可以通过购买或者其他方式，使用微吼的软件和服务开展直播活动或者观看直播活动，进行直播互动。
        </p>
        <p class="level-2">
          您知晓并同意，微吼运用自己的系统通过互联网向用户提供服务，除非另有明确规定，微吼对于服务的任何新功能的增强或者优化，包括但不限于新产品的推出以及新服务的迭代，均无条件地适用本协议。
        </p>
        <p class="level-2">
          您知晓并同意，微吼提供的是软件和服务，并不直接生产内容。微吼对微吼服务承担操作系统以下的底层部分及平台的运营维护责任。
          <span class="font-bold font-italic">
            受限于技术水平，微吼不保证服务可以满足每一位用户的使用要求，也不保证服务不会被中断（尤其是非可归因于微吼的场地、电力、网络，或者当前技术限制等客观原因所导致的中断）。微吼不对服务的及时性、安全性、准确性作担保。
          </span>
        </p>
      </section>
    </div>
    <div>
      <p class="level-1 font-bold">三、微吼服务的试用</p>
      <section>
        <p class="level-2">
          当您登录微吼官方网站、APP、客户端应用程序等，如果您想要试用微吼的产品和服务或者在线咨询（购买咨询），您同意微吼需要您留下姓名、手机号码、公司、职位等信息，并同意微吼对您预留的信息进行处理。为了更好的为您提供服务，并给予您相应的试用指导，您同意并认可微吼客服有权通过您预留的联系方式给您打电话、发短信、添加微信好友、发送电子邮件等方式与您联系。您进一步同意，微吼客服除了向您进行微吼产品服务介绍、使用功能指导之外，微吼客服有权为您介绍微吼产品和服务的商务价格、商务策略等。
        </p>
        <p class="level-2">
          如您不同意微吼客服的前述服务，在您接到电话、收到短信、电子邮件、添加微信好友等时可以明确拒绝。微吼的客服将中止相关行为。
        </p>
      </section>
    </div>
    <div>
      <p class="level-1 font-bold">四、账号密码安全</p>
      <section>
        <p class="level-2">
          除本协议另有约定或者相关产品另有规则外，您须在微吼官方网站、APP、客户端应用程序等注册并取得微吼提供给您的账号。如根据有关法律法规、微吼最新规则等，您应当按照微吼要求提供相关信息完成激活或者信息补充后方可使用微吼的产品和服务。如您账号信息变更，您可通过协议预留的微吼联系方式向微吼申请变更，经微吼确认后予以变更。
        </p>
        <p class="level-2">
          您一旦注册成功，便成为微吼的用户并将得到一个微吼账号。您应采取合理措施维护您账号、密码的安全。因账号密码保护不善致使信息泄露或者由于第三方盗用账号进行操作而引发的后果由您自担。出现前述情形，微吼将尽量按照您的要求协助处理。
        </p>
        <p class="level-2">
          为更好的保护您的账号安全，微吼可以对账号进行监测。如发现使用异常，为避免您损失扩大，微吼有权但无义务临时冻结账号并通知您。账号异常情形包括但不限于：密码泄漏、频繁登录验证失败、异常IP访问、账号资产被异常消耗、账号余额被异常转出等。
        </p>
        <p class="level-2">
          当您在使用微吼服务过程中，如发现他人冒用或者盗用您的账号或者任何其他未经合法授权之情形，或者发生其他任何安全问题时，您应立即以有效方式通知微吼，向微吼申请暂停相关服务，以保障您的合法权益。为尽可能避免风险，微吼提醒您，在您每次登录账号开展、参与直播活动结束后，应结束账号使用，否则您的账号无法得到微吼的安全保护。
        </p>
        <p class="level-2">
          微吼不能也不会对因您未能遵守本协议约定而发生的任何损失、损毁及其他不利后果负责。您理解微吼对您的请求采取行动需要合理期限，在微吼采取措施之前的损失等由您自己承担。如您需要向相关实施行为的人员追究责任，微吼将全力协助您并提供力所能及的帮助。
        </p>
      </section>
    </div>
    <div>
      <p class="level-1 font-bold">五、账号注销管理</p>
      <section>
        <p class="level-2">
          如您需要终止继续使用微吼的产品和服务时，您可以将要注销手机号、注销原因以邮件的形式发送到邮箱：customerservice@vhall.com，并按照微吼规定的程序注销您的微吼账号。微吼账号注销后，微吼将不再为您提供相应的产品和服务。微吼账号注销后，您通过微吼账号的行为信息（如有）以及相关附属文档（如有）将无法查看。
        </p>
        <p class="level-2">
          对于用户长时间未使用的账号，微吼有权予以冻结或者注销。如您在微吼官方网站、APP、客户端应用程序等有欺诈、虚假交易、侵犯他人合法权益或者其他违反本协议或者中国法律、法规行为的，微吼有权注销您账号。您将不能再登录微吼账号，并不能继续使用微吼的产品和服务。
        </p>
      </section>
    </div>
    <div>
      <p class="level-1 font-bold">六、产品与服务的购买</p>
      <section>
        <p class="level-2">
          如您想购买微吼的产品和服务，可以通过与微吼签订《网络直播服务合同》（以下简称“服务合同”。单独的服务合同可能是纸质合同、也可能是电子合同、扫描件等数据电文形式的服务合同）。服务合同、规则等是本协议的组成部分，与本协议共同构成双方的权利义务关系。
        </p>
        <p class="level-2">
          微吼可能根据产品和服务的属性、成本费用等对收费服务的收费标准、方式进行修改和变更，也可能会对部分免费服务开始收费。前述修改、变更或开始收费前，微吼会通过站内消息、网页公告、电话、微信、电子邮件等形式方式通知您，并确保您享有知情权和自由选择的权利。
        </p>
        <p class="level-2">
          您应当按照服务合同、规则等及时足额的支付服务费用。当您未及时足额支付相关服务费用时，微吼有权拒绝开通服务或者无需另行通知您而直接中止或者终止服务，并可采取如下措施追究违约责任：
        </p>
        <p class="level-2">
          1、要求您继续支付应当支付的服务费用，同时按照未付服务费总金额1‰/天的标准向微吼支付违约金（服务合同或者规则等与本协议约定不一致的，适用违约金金额最高的）；
        </p>
        <p class="level-2">
          2、如您有逾期付款的情形，微吼有权无需另行通知您，而直接以您预付费用或者账户余额直接用于抵扣逾期款项；
        </p>
        <p class="level-2">3、逾期付款超过15天的，微吼还有权解除服务合同并追究您的全部违约责任。</p>
        <p class="level-2">
          如您在中国以外的国家或者地区使用微吼的产品和服务，请您遵守中国的法律、法规和规范性文件的要求，同时你还要遵守使用地有关国家、地区的法律规定。
        </p>
      </section>
    </div>
    <div>
      <p class="level-1 font-bold">七、行为规范</p>
      <section>
        <p class="level-2">
          您应当遵守相关的法律、法规和规范性文件的要求，以及微吼的相关的协议、政策和规则实施行为。如您与第三方发生商品和服务交易以及其他民事法律行为，请您按照您与第三方的合同约定享有权利、履行义务。
        </p>
        <p class="level-2">
          如您是商户，请您按照有关法律规定和与用户的约定提供合格的产品和服务。如果您是用户，请您根据真实的需求采购相应的商品和服务，不得恶意购买、恶意评价、恶意维权等扰乱交易秩序的行为。
        </p>
        <p class="level-2">
          在直播间，您应当注意自己的言行举止，尊重社会公德和商业道德。法律充分保障您的言论自由权利，但是法律也坚决抵制并打击违法违规的不当言论。
        </p>
        <p class="level-2">当您与其他人发生纠纷时，您可以选择如下途径解决：</p>
        <p class="level-2">1、纠纷各方协商解决；</p>
        <p class="level-2">2、请求微吼介入并协助调解；</p>
        <p class="level-2">3、向消费者协会或者其他依法设立的组织申请调解；</p>
        <p class="level-2">4、向行政机关投诉；</p>
        <p class="level-2">
          5、申请民商事仲裁（如果您与纠纷主体有仲裁合同）或者依法向人民法院起诉。
        </p>
        <p class="level-2">
          您应当对您本人或者您的雇员等的行为负责。您应当对您账号项下的发布的内容、言论、行为等承担全部的法律责任。
        </p>
      </section>
    </div>
    <div>
      <p class="level-1 font-bold">八、开展直播活动的禁止</p>
      <section>
        <p class="level-2">
          如您利用微吼产品和服务从事生产经营活动，应当遵守《网络安全法》、《数据安全法》、《个人信息保护法》、《反洗钱法》、《电信条例》等法律法规的规定。您通过微吼服务，发布、传播、传送的内容不得有以下情形：
        </p>
        <p class="level-2">1、反对宪法所确定的基本原则；</p>
        <p class="level-2">2、危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一；</p>
        <p class="level-2">3、损害国家荣誉和利益；</p>
        <p class="level-2">4、煽动民族仇恨、民族歧视、破坏民族团结；</p>
        <p class="level-2">5、破坏国家宗教政策，宣扬邪教和封建迷信；</p>
        <p class="level-2">6、散布谣言，扰乱社会秩序，破坏社会稳定；</p>
        <p class="level-2">7、散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪；</p>
        <p class="level-2">8、侮辱或者诽谤他人，侵害他人合法权益；</p>
        <p class="level-2">9、煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；</p>
        <p class="level-2">10、以非法民间组织名义活动；</p>
        <p class="level-2">
          11、含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或者其它道德上令人反感的内容；
        </p>
        <p class="level-2">
          12、通过直播平台，利用抢红包、打赏、转账、虚假交易等方式实施洗钱活动；
        </p>
        <p class="level-2">13、以任何方式危害未成年人；</p>
        <p class="level-2">
          14、冒充任何人或者机构，或者以虚伪不实的方式谎称或者使人误认为与任何人或者任何机构有关；
        </p>
        <p class="level-2">
          15、伪造标题或者以其他方式操控识别资料，使人误认为该内容为微吼直播所传送；
        </p>
        <p class="level-2">
          16、将无权传送的内容（例如内部资料、机密资料）进行直播、录播、预约直播或者以其他方式传送；
        </p>
        <p class="level-2">
          17、将侵犯任何人的专利、商标、著作权、商业秘密或者其他专属权利之内容加以直播、录播、预约直播或者以其他方式传送；
        </p>
        <p class="level-2">
          18、将广告函件、促销资料、"垃圾邮件"等，加以直播、录播、预约直播或者以其他方式传送。供前述目的使用的专用区域除外；
        </p>
        <p class="level-2">
          19、将有关干扰、破坏或者限制任何计算机软件、硬件或者通讯设备功能的软件病毒或者其他计算机代码、档案和程序之资料，加以直播、录播、预约直播或者以其他方式传送；
        </p>
        <p class="level-2">
          20、干扰或者破坏本网站服务或者与本网站服务相连的服务器和网络，或者不遵守本网站使用之规定；
        </p>
        <p class="level-2">21、其他违反相关法律、法规、规范性文件的行为。</p>
        <p class="level-2">
          发现您利用微吼产品和服务开展的直播活动中有前述行为的，微吼有权制止。无法制止的，微吼有权中止和/或者终止服务并不退还任何款项，删除相关内容，直至注销您的微吼账号，并有权向有关司法行政机关检举报案。违反上述约定引发的政治、法律和经济责任由您承担。违反上述规定给微吼造成罚金、赔偿金、补偿金、取证费、律师费、诉讼费等损失的，您应全额补偿微吼。如因上述行为致使微吼承担法律责任或者遭受调查、诉讼的，您保证自担人力、成本和费用确保微吼免受相应的法律责任，并赔偿微吼因此遭受的全部损失。
        </p>
      </section>
    </div>
    <div>
      <p class="level-1 font-bold">九、对主播的依法管理</p>
      <section>
        <p class="level-2">
          如果您使用微吼的产品和服务进行音视频直播、播放提前录制好的音视频等，您应当确保您自己，或者您所雇佣或者委托的提供网络表演、视听节目服务的主播人员（包括在网络平台直播、与用户进行实时交流互动、以上传音视频节目形式发声出镜的人员，以及利用人工智能技术合成的虚拟主播及内容）应当遵守《网络主播行为规范》。
        </p>
        <p class="level-2">
          音视频内容涉及医疗卫生、财经金融、法律、教育的，您或者您应当确保主播具有相应的从业资格并对其进行资质审核及备案。您应当自己遵守或者确保网络主播在提供网络表演及视听节目服务过程中不得出现下列行为：
        </p>
        <p class="level-2">1、发布违反宪法所确定的基本原则及违反国家法律法规的内容；</p>
        <p class="level-2">
          2、发布颠覆国家政权，危害国家统一、主权和领土完整，危害国家安全，泄露国家秘密，损害国家尊严、荣誉和利益的内容；
        </p>
        <p class="level-2">
          3、发布削弱、歪曲、否定中国共产党的领导、社会主义制度和改革开放的内容；
        </p>
        <p class="level-2">
          4、发布诋毁民族优秀文化传统，煽动民族仇恨、民族歧视，歪曲民族历史或者民族历史人物，伤害民族感情、破坏民族团结，或者侵害民族风俗、习惯的内容；
        </p>
        <p class="level-2">
          5、违反国家宗教政策，在非宗教场所开展宗教活动，宣扬宗教极端主义、邪教等内容；
        </p>
        <p class="level-2">
          6、恶搞、诋毁、歪曲或者以不当方式展现中华优秀传统文化、革命文化、社会主义先进文化；
        </p>
        <p class="level-2">7、恶搞、歪曲、丑化、亵渎、否定英雄烈士和模范人物的事迹和精神；</p>
        <p class="level-2">
          8、使用换脸等深度伪造技术对党和国家领导人、英雄烈士、党史、历史等进行伪造、篡改；
        </p>
        <p class="level-2">9、损害人民军队、警察、法官等特定职业、群体的公众形象；</p>
        <p class="level-2">10、宣扬基于种族、国籍、地域、性别、职业、身心缺陷等理由的歧视；</p>
        <p class="level-2">
          11、宣扬淫秽、赌博、吸毒，渲染暴力、血腥、恐怖、传销、诈骗，教唆犯罪或者传授犯罪方法，暴露侦查手段，展示枪支、管制刀具；
        </p>
        <p class="level-2">
          12、编造、故意传播虚假恐怖信息、虚假险情、疫情、灾情、警情，扰乱社会治安和公共秩序，破坏社会稳定；
        </p>
        <p class="level-2">
          13、展现过度的惊悚恐怖、生理痛苦、精神歇斯底里，造成强烈感官、精神刺激并可致人身心不适的画面、台词、音乐及音效等；
        </p>
        <p class="level-2">14、侮辱、诽谤他人或者散布他人隐私，侵害他人合法权益；</p>
        <p class="level-2">15、未经授权使用他人拥有著作权的作品；</p>
        <p class="level-2">16、对社会热点和敏感问题进行炒作或者蓄意制造舆论“热点”；</p>
        <p class="level-2">
          17、炒作绯闻、丑闻、劣迹，传播格调低下的内容，宣扬违背社会主义核心价值观、违反公序良俗的内容；
        </p>
        <p class="level-2">18、服饰妆容、语言行为、直播间布景等展现带有性暗示、性挑逗的内容；</p>
        <p class="level-2">
          19、介绍或者展示自杀、自残、暴力血腥、高危动作和其他易引发未成年人模仿的危险行为，表现吸烟、酗酒等诱导未成年人不良嗜好的内容；
        </p>
        <p class="level-2">
          20、利用未成年人或者未成年人角色进行非广告类的商业宣传、表演或者作为噱头获取商业或者不正当利益，指引错误价值观、人生观和道德观的内容；
        </p>
        <p class="level-2">21、宣扬封建迷信文化习俗和思想、违反科学常识等内容；</p>
        <p class="level-2">22、破坏生态环境，展示虐待动物，捕杀、食用国家保护类动物等内容；</p>
        <p class="level-2">
          23、铺张浪费粮食，展示假吃、催吐、暴饮暴食等，或者其他易造成不良饮食消费、食物浪费示范的内容；
        </p>
        <p class="level-2">
          24、引导用户低俗互动，组织煽动粉丝互撕谩骂、拉踩引战、造谣攻击，实施网络暴力；
        </p>
        <p class="level-2">
          25、营销假冒伪劣、侵犯知识产权或者不符合保障人身、财产安全要求的商品，虚构或者篡改交易、关注度、浏览量、点赞量等数据流量造假；
        </p>
        <p class="level-2">
          26、夸张宣传误导消费者，通过虚假承诺诱骗消费者，使用绝对化用语，未经许可直播销售专营、专卖物品等违反广告相关法律法规的；
        </p>
        <p class="level-2">27、通过“弹幕”、直播间名称、公告、语音等传播虚假、骚扰广告；</p>
        <p class="level-2">
          28、通过有组织炒作、雇佣水军刷礼物、宣传“刷礼物抽奖”等手段，暗示、诱惑、鼓励用户大额“打赏”，引诱未成年用户“打赏”或者以虚假身份信息“打赏”；
        </p>
        <p class="level-2">
          29、在涉及国家安全、公共安全，影响社会正常生产、生活秩序，影响他人正常生活、侵犯他人隐私等场所和其他法律法规禁止的场所拍摄或者播出；
        </p>
        <p class="level-2">
          30、展示或者炒作大量奢侈品、珠宝、纸币等资产，展示无节制奢靡生活，贬低低收入群体的炫富行为；
        </p>
        <p class="level-2">
          31、法律法规禁止的以及其他对网络表演、网络视听生态造成不良影响的行为。
        </p>
        <p class="level-2">
          微吼支持国家依照有关法律法规全方位引导弘扬优良文化、抵制低俗风气、宣传正确导向、谴责奢靡炫富、打击违法犯罪等。发现您利用微吼产品和服务开展的直播活动中有前述行为的，微吼有权制止。无法制止的，微吼有权中止和/或者终止服务并不退还任何款项，删除相关内容，直至注销您的微吼账号，并有权向有关司法行政机关检举报案。违反上述约定引发的政治、法律和经济责任由您承担。违反上述规定给微吼造成罚金、赔偿金、补偿金、取证费、律师费、诉讼费等损失的，您应全额补偿微吼。如因上述行为致使微吼承担法律责任或者遭受调查、诉讼的，您保证自担人力、成本和费用确保微吼免受相应的法律责任，并赔偿微吼因此遭受的全部损失。
        </p>
      </section>
    </div>
    <div>
      <p class="level-1 font-bold">十、网络直播营销特别规定</p>
      <section>
        <p class="level-2">
          如果您利用微吼的产品和服务开展网络直播营销活动，您应当确保直播营销人员或者直播间运营者为自然人的，应当年满十六周岁；十六周岁以上的未成年人申请成为直播营销人员或者直播间运营者的，应当经监护人同意。您通过微吼举办的直播活动参与电子商务经营的，应当依法办理市场主体登记（依法不需要登记的除外），法律、行政法规对参与主体有资质资格要求的，您应当确保他们已经获得相应的资质资格。
        </p>
        <p class="level-2">
          您应当并确保从事网络直播营销活动的主播人员遵循社会公序良俗，真实、准确、全面地发布商品或者服务信息，不得有下列行为：
        </p>
        <p class="level-2">1、发布虚假或者引人误解的信息，欺骗、误导用户；</p>
        <p class="level-2">2、营销假冒伪劣、侵犯知识产权或者不符合保障人身、财产安全要求的商品；</p>
        <p class="level-2">3、虚构或者篡改交易、关注度、浏览量、点赞量等数据流量造假；</p>
        <p class="level-2">4、知道或者应当知道他人存在违法违规或者高风险行为，仍为其推广、引流；</p>
        <p class="level-2">5、骚扰、诋毁、谩骂及恐吓他人，侵害他人合法权益；</p>
        <p class="level-2">6、传销、诈骗、赌博、贩卖违禁品及管制物品等；</p>
        <p class="level-2">7、其他违反国家法律法规和有关规定的行为。</p>
        <p class="level-2">
          依据法律法规的规定，您应当确保不得以网络直播形式推销或者提供下列商品或者服务：
        </p>
        <p class="level-2">1、国家明令淘汰并停止销售的商品和失效、变质的商品；</p>
        <p class="level-2">2、不符合保障人体健康和人身、财产安全的强制性国家标准的商品或者服务；</p>
        <p class="level-2">3、无质量检验合格证明或者无中文标明产品名称、生产厂厂名和厂址的商品；</p>
        <p class="level-2">4、依法应当取得许可、备案或者强制性认证而未取得的商品或者服务；</p>
        <p class="level-2">5、损害国家利益和社会公共利益，违背公序良俗的商品或者服务；</p>
        <p class="level-2">6、不符合环境保护要求的商品或者服务；</p>
        <p class="level-2">7、侵犯他人知识产权的商品或者服务；</p>
        <p class="level-2">
          8、麻醉药品、精神药品、医疗用毒性药品、放射性药品等特殊药品，药品类易制毒化学品，戒毒治疗的药品、医疗器械和治疗方法等法律、行政法规禁止发布广告的商品和服务；
        </p>
        <p class="level-2">
          9、处方药、烟草制品（含电子烟）、声称全部或者部分替代母乳的婴儿乳制品、饮料和其他食品等法律、行政法规禁止在大众传播媒介发布广告的商品和服务；
        </p>
        <p class="level-2">10、法律、行政法规禁止网上交易的其他商品和服务。</p>
        <p class="level-2">
          发现您利用微吼产品和服务开展的直播活动中有前述行为的，微吼有权制止。无法制止的，微吼有权中止和/或者终止服务并不退还任何款项，删除相关内容，直至注销您的微吼账号，并有权向有关司法行政机关检举报案。违反上述约定引发的政治、法律和经济责任由您承担。违反上述规定给微吼造成罚金、赔偿金、补偿金、取证费、律师费、诉讼费等损失的，您应全额补偿微吼。如因上述行为致使微吼承担法律责任或者遭受调查、诉讼的，您保证自担人力、成本和费用确保微吼免受相应的法律责任，并赔偿微吼因此遭受的全部损失。
        </p>
      </section>
    </div>
    <div>
      <p class="level-1 font-bold font-italic">十一、责任限制</p>
      <section>
        <p class="level-2 font-bold font-italic">
          因不可抗力导致未能履行或者未能全部履行本协议项下义务的，微吼可免除责任。不可抗力是指不能预见、不能避免且不能克服的客观情况，包括但不限于自然灾害如洪水、火灾、爆炸、雷电、地震和风暴等以及社会事件如基础电信运营商网络出现严重电力或者网络故障，战争、动乱、政府行为、国家政策的突然变动和罢工，以及黑客或者病毒攻击等。
        </p>
        <p class="level-2 font-bold font-italic">
          鉴于计算机及互联网的特殊性，因黑客、病毒、电信部门技术或者政策调整等引起的事件，或者微吼为进行服务器配置、维护而短时间中断服务，或者由于Internet上通路的阻塞造成您服务受到影响，微吼不承担责任。
        </p>
        <p class="level-2 font-bold font-italic">
          微吼对因第三方原因而给您或者其他方造成的损失不承担责任，但会积极配合您采取措施向该第三方索赔。
        </p>
        <p class="level-2 font-bold font-italic">
          微吼的服务平台如托管于公共互联网第三方数据中心，尽管微吼会尽力对其进行适用性考核，确认其达到和满足相关的技术标准，但微吼在此声明不对第三方托管数据中心以及国家骨干网、或者您接入互联网的条件承担任何责任。
        </p>
        <p class="level-2 font-bold font-italic">
          为向您提供更完善的产品和服务，微吼有权不定期对产品和服务平台或相关设备、系统、软件等进行检修、维护、优化升级等，因此产生的服务中断或不稳定状态，不视为微吼违约，但微吼有义务尽快解决中断或不稳定状态。
        </p>
        <p class="level-2 font-bold font-italic">
          为了改善用户体验、完善服务内容，微吼将不断开发产品和服务的新功能，并不时提供产品和服务的更新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）。除此以外，基于法律的变化或者运营和交易安全，微吼有权对产品和服务的部分功能进行改变或限制。
        </p>
        <p class="level-2 font-bold font-italic">
          有限责任：在中国允许的最大限度内，任何情形下，微吼不对任何特别、附带及间接损害（包括但不限于商业利润损失、业务中断、商业信息损失、商誉损失、购买替代产品及/或者服务或者其它金钱损失等损害）负责。
        </p>
        <p class="level-2 font-bold font-italic">
          有限保证：您理解并同意微吼服务及相关软件均“按现状”和“可用性”提供，微吼有权但无义务将微吼的软件进行相应的更新。
        </p>
        <p class="level-2 font-bold font-italic">
          您知悉微吼无法保证所提供的服务毫无瑕疵（如微吼安全产品并不能保证您的硬件或者软件的绝对安全），但微吼承诺不断提升服务质量及服务水平。所以您同意：即使微吼提供的服务存在瑕疵，但上述瑕疵是行业技术水平所无法避免的，其将不被视为微吼违约。您同意和微吼一同合作解决前述瑕疵。基于本协议，在任何情况下，微吼所承担的赔偿责任总额不超过您和微吼签订服务合同约定的当次服务的服务费总额，如果服务费超过人民币五万元的，微吼承担的赔偿总额不超过五万元。
        </p>
      </section>
    </div>
    <div>
      <p class="level-1 font-bold">十二、知识产权归属</p>
      <section>
        <p class="level-2">
          微吼对微吼官方网站、APP、客户端应用程序等以及微吼的产品和服务所有内容，包括但不限于官方网站、APP、客户端应用程序等的构架、画面安排、设计、著作、图片、档案、资讯、资料等依法拥有全部知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。
        </p>
        <p class="level-2">
          未经微吼书面同意，任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或者公开发表本网站程序或者内容，但是您将微吼的官方网站、APP、客户端应用程序推荐给他人的行为不视为违反了本协议。尊重知识产权是您应尽的义务，如有违反应当承担损害赔偿责任。
        </p>
        <p class="level-2">
          您利用微吼的软件实施的直播活动保证不侵犯包括微吼在内的任何人的知识产权，您保证已合法拥有相关素材及作品的知识产权或已取得第三方的合法授权，包括但不限于使用的图片、文字、字体、音乐、影视片段、音视频、文学艺术作品、肖像权等。其中关于您利用微吼的软件实施的直播活动中所有的图片、文字等呈现完全依赖用户终端设备本地字体库及图片库。微吼作为直播技术服务提供方，未集成任何第三方字体库/图片库，且无内置字体/图片包，微吼无权且无义务核查用户本地字体/图片的来源。如您违反本协议，致使权利人向微吼追究责任的，您承诺自担费用和人力解决相关争议。微吼因此遭受的一切损失，包括但不限于赔偿金、调解金、和解金、罚款、诉讼费、律师费、公证费、差旅费等均由您承担。
        </p>
        <p class="level-2">
          为一般性市场目的，您同意微吼有权将双方的合作关系（您的名称、标识）以及您在微吼直播平台上公开的内容（包括直播内容、播放的录播内容等）进行宣传、展示、推广。
        </p>
      </section>
    </div>
    <div>
      <p class="level-1 font-bold">十三、用户信息及隐私保护</p>
      <section>
        <p class="level-2">
          微吼十分重视您的个人信息和隐私保护。微吼将严格依照《网络安全法》、《数据安全法》、《个人信息保护法》等有关法律法规的规定处理和保护您的个人信息和隐私。有关个人信息和隐私保护请见《微吼隐私政策》。
        </p>
      </section>
    </div>
    <div>
      <p class="level-1 font-bold">十四、法律适用和争议解决</p>
      <section>
        <p class="level-2">
          本协议受中国法律管辖并按中国法律解释（为本协议之目的，中国仅指中国大陆地区，不包括中国香港、中国澳门和中国台湾）。本协议的任何条款如与届时有效的中国法律相冲突而无效的，不影响其他部分的效力。
        </p>
        <p class="level-2 font-bold font-italic">
          因本协议条款及履行过程中发生的任何争议，各方同意友好协商解决。如协商不成，各方同意提交北京市朝阳区人民法院通过诉讼方式解决。
        </p>
      </section>
    </div>
    <div>
      <p class="level-1 font-bold">十五、通知送达及推送</p>
      <section>
        <p class="level-2">
          本协议项下，微吼可以通过网页公告、站内消息、公众号通知、您预留的电子邮件、手机短信、电话、信件传送等方式中的任何一种向您发送、送达相关通知、告知、函件等，该等通知于发送之日视为已送达。
        </p>
        <p class="level-2">
          请您确保您在微吼平台预留的手机号码、电子邮件、收件地址等的准确。如发生变更，请您及时进行修改。
          <span class="font-bold font-italic">
            联系方式变更后未提供新方式或者您预留的联系方式不准确，微吼按照既有联系方式中任何一种向您送达相关通知、告知、函件等，视为有效送达，由此产生的法律后果和损失由您承担。
          </span>
        </p>
        <p class="level-2">
          您同意微吼有权通过您预留的电话号码、电子邮件等给您打电话、发短信、发送电子邮件等向您介绍、推销与微吼相关的产品或者服务，或者与微吼不相关的信息，如第三方活动信息、交易信息、商业广告等。如您对相关推送感到不适，您可以直接拒绝，微吼将中止相关行为。
        </p>
      </section>
    </div>
    <div>
      <p class="level-1 font-bold">十六、附则</p>
      <section>
        <p class="level-2 font-bold font-italic">
          微吼再次声明，微吼是一个可以为包括您在内的客户提供直播的软件技术平台，微吼不直接产生内容，不参与您的直播活动，仅仅提供软件和技术服务。作为微吼软件的使用者，您应当按照本协议以及与微吼签订的任何其他合同支付相关费用。如您不支付/预付相关费用，和/或者相关服务消耗完不续费的，微吼可以拒绝提供服务，和/或者中止（终止）服务。您预付的费用在服务有效期内相关服务未使用的，微吼有权不予退款、不予折现、不予延期。
        </p>
        <p class="level-2">
          如您对本协议相关内容需要进一步了解，或者对于本协议或者微吼的产品服务有任何疑问或者建议，以及涉及本协议约定需要与平台取得联系的其他情形，您可以通过以下方式与微吼联系：
        </p>
        <p class="level-2">1、电话：400-888-9970</p>
        <p class="level-2">2、邮箱：customer@vhall.com</p>
        <p class="level-2">
          3、邮寄地址：北京市朝阳区东亿文化产业园三期B座8层 财法中心法务合规部（收）
        </p>
        <p class="level-2">
          微吼将尽快审核您提出的问题或者建议，并在验证您的用户身份后的十五个工作日内回复。
        </p>
      </section>
    </div>
  </div>
</template>
<script>
  import OldHeader from '@/components/OldHeader';
  export default {
    components: {
      OldHeader
    },
    created() {
      this.judgeIsWap();
      window.addEventListener('resize', () => {
        this.judgeIsWap();
      });
    },
    methods: {
      judgeIsWap() {
        const uA = navigator.userAgent.toLowerCase();
        const ipad = uA.match(/ipad/i) == 'ipad';
        const iphone = uA.match(/iphone os/i) == 'iphone os';
        const midp = uA.match(/midp/i) == 'midp';
        const uc7 = uA.match(/rv:1.2.3.4/i) == 'rv:1.2.3.4';
        const uc = uA.match(/ucweb/i) == 'ucweb';
        const android = uA.match(/android/i) == 'android';
        const windowsce = uA.match(/windows ce/i) == 'windows ce';
        const windowsmd = uA.match(/windows mobile/i) == 'windows mobile';
        const dom = document.getElementById('app');
        if (!(ipad || iphone || midp || uc7 || uc || android || windowsce || windowsmd)) {
          // PC 端
          dom.style.minWidth = 'unset';
          dom.style.width = '100%';
          dom.style.overflowX = 'hidden';
          dom.style.backgroundColor = '#F5F5F5';
          dom.className = 'page_pc';
        } else {
          // 移动端
          dom.style.minWidth = 'unset';
          dom.style.width = '100%';
          dom.style.overflowX = 'hidden';
          dom.style.backgroundColor = '#F5F5F5';
          dom.className = 'page_mobile';
        }
      }
    }
  };
</script>
<style lang="less" scoped>
  .user-protection {
    padding: 0 0;
    a {
      color: #3562fa;
      &:link {
        color: #3562fa;
      } //设置未访问时的超链接样式
      &:visited {
        color: #3562fa;
      } //设置已访问的链接的样式
      &:hover {
        color: #3562fa;
      } //设置鼠标移动到链接上的样式
      &:active {
        color: #3562fa;
      } //设置链接被激活（鼠标点击与释放之间）时的样式
    }
    > div {
      margin-bottom: 24px;
      &.one {
        margin-top: 24px;
      }
    }
    h1.p-title {
      text-align: center;
      padding-bottom: 24px;
      border-bottom: 1px solid #dcdcdc;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 44px;
      color: #262626;
      margin-bottom: 24px;
      padding-top: 40px;
    }
    p {
      text-align: left;
      word-break: break-all;
    }
    p.p-timer {
      margin-bottom: 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      text-align: left;
      color: #262626;
      &.margin-24 {
        margin-bottom: 28px;
      }
    }
    p.level-1 {
      margin-bottom: 12px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #262626;
    }
    p.level-2 {
      text-indent: 32px;
      margin-bottom: 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #262626;
      &:last-child {
        margin-bottom: 0;
      }
    }
    section {
      margin-bottom: 12px;
    }
    .table-title {
      margin-top: 32px;
    }

    .font-italic {
      font-style: italic !important;
    }
    .font-bold {
      font-weight: bold !important;
    }
  }
  .page_pc {
    p,
    h1,
    table {
      margin-left: 83px;
      margin-right: 83px;
    }
    //  .privacy-policy {

    //   }
  }
  .page_mobile {
    p,
    h1,
    table {
      margin-left: 24px;
      margin-right: 24px;
    }
    // .privacy-policy {

    // }
  }
</style>
